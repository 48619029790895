export const answerContent = [
  {
    id: 1,
    name: "Lactose intolerance"
  },
  {
    id: 2,
    name: "Celiac disease"
  },
  {
    id: 3,
    name: "Egg  allergy"
  },
  {
    id: 4,
    name: "Fish Allergy"
  },
  {
    id: 5,
    name: "Nut allergy"
  }
]