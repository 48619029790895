import React from 'react';
import ServiceInfo from "./healthyitemspreview/ServiceInfo"

const HealthyItemsPreview = () => {
  return (
    <>
      <ServiceInfo />
    </>
  );
};

export default HealthyItemsPreview;
