export const answerContent = [
  {
    id: 1,
    name: "Follow a specific diet (e.g. vegan)"
  },
  {
    id: 2,
    name: "Weight loss"
  },
  {
    id: 3,
    name: "Receive nutritional education"
  },
  {
    id: 4,
    name: "Muscle Gain"
  }
]