import "./contactintro.css"

const ContactIntro = () => {
  return(
    <div className="contact-intro">
      <h1 className="title">Contact</h1>
    </div>
  )
}

export default ContactIntro